import {Alert, Modal, StyleSheet, Text, Pressable, View} from 'react-native';
import React, {useState} from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import Home from '../Screens/Home';
import Search from '../Screens/Search';
import Localiza from '../Screens/Localiza';
import Tbd from '../Screens/Tbd';
import HomeStackNavigation from './HomeStackNavigation';
import ModalMenuStackNavigation from './ModalMenuStackNavigation';
import VideoFull from '../Screens/VideoFull';
import SimpleImageMapper from '../Components/SimpleImageMapper';
import LogoTitle from '../Components/LogoTitle'
import LocalizaZoom from '../Screens/LocalizaZoom';
import LocalizaZoomCtrl from '../Screens/LocalizaZoomCtrl';
import TesteWebview from '../Screens/TesteWebview';
import Aguarde from '../Screens/Aguarde';
import PermiteGeo from '../Screens/PermiteGeo';

export default function TabNavigation() {
  const Tab=createBottomTabNavigator();
  return (
    <Tab.Navigator screenOptions={{
        headerShown:false,
        tabBarShowLabel: false,
        tabBarStyle: { backgroundColor: '#FBC429' },
        tabBarInactiveTintColor: '#555',
        tabBarActiveTintColor: '#000',
        unmountOnBlur: true,
    }}>
      <Tab.Screen name="Home" component={HomeStackNavigation} 
      options={{
        tabBarLabel: '',
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="home" color={color} size={size} />
        ),

      }} />
      <Tab.Screen name="Localiza" component={TesteWebview}
       options={{
        tabBarLabel: '',
        headerShown: true,
        headerTitle: (props) => <LogoTitle {...props} /> ,
        headerTitleStyle: { textAlign: 'center', alignSelf: 'center' },
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerTintColor: '#fff',
        headerShadowVisible: false,
        headerStyle: {
            backgroundColor: '#000',
        },        
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="location" color={color} size={size} />
        ),
      }} />      
      <Tab.Screen name="Search" component={Aguarde}
       options={{
        tabBarLabel: '',
        headerShown: true,
        headerTitle: (props) => <LogoTitle {...props} /> ,
        headerTitleStyle: { textAlign: 'center', alignSelf: 'center' },
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerTintColor: '#fff',
        headerShadowVisible: false,
        headerStyle: {
            backgroundColor: '#000',
        },
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="search" color={color} size={size} />
        ),
      }} />
      <Tab.Screen name="MenuSuspenso" 
       component={ModalMenuStackNavigation} 
       options={{
        tabBarLabel: '',
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="ios-menu-outline" color={color} size={size} />
        ),        
       }}
       />
    </Tab.Navigator>
  )
}