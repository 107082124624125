import { StyleSheet } from 'react-native'
import {View, TextField, Text, Button, Image, Card} from 'react-native-ui-lib';
import React from 'react'
import NewsList from '../Components/NewsList'

export default function Home({navigation}) {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
    },
  });

  return (
    <View flex marginT-0 bg-white>
      <NewsList></NewsList>
    </View>
  )
}