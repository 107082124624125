import { StyleSheet, Text, View, Dimensions, ImageBackground } from 'react-native'
import React, {useEffect, useState} from 'react'
import * as Location from 'expo-location';
import { useNavigation } from '@react-navigation/native';

export default function PermiteGeoTeste() {
    const navigation = useNavigation();
    const [data, setData] = useState(null);
    const getData = async () => {
        let { status } = await Location.requestForegroundPermissionsAsync();
        //let { status } = await Location.getForegroundPermissionsAsync();
        console.log(status)    
        setData(status);
        navigation.navigate('HomeTabs', {
            teste: 1
        });

    };
    useEffect(() => {
        getData();
    }, []);    

  return (
    <View flex-1 style={{ flex:1, 
        flexDirection:"column", 
        height: Dimensions.get('screen').height,
        justifyContent: 'space-around',
      }}>
        <ImageBackground
            source={require('../../assets/tela_entrada_opt.png')}
            resizeMode='cover'
            style={{flex:1}}
        >
        </ImageBackground>
    </View>

  )
}

const styles = StyleSheet.create({})