import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from '../Screens/Home';
import Abertura from '../Screens/Abertura';
import TabNavigation from './TabNavigation';
import PermiteGeoTeste from '../Screens/PermiteGeoTeste';

const InitialStack = createNativeStackNavigator();

export default function InitialStackNavigation() {
  return (
    <InitialStack.Navigator 
        screenOptions={{ headerShown: false }}
    >
      <InitialStack.Screen 
        name="Abertura" 
        component={Abertura} 
        options={
            {
              headerBackVisible: false
            }
        }
        />

      <InitialStack.Screen 
        name="PermiteGeoTeste" 
        component={PermiteGeoTeste} 
        options={
            {
              headerBackVisible: true
            }
        }
        />

      <InitialStack.Screen 
            name="HomeTabs" 
            component={TabNavigation} 
            options={
                {
                  headerBackVisible: false
                }
            }
            />
    </InitialStack.Navigator>
  )
}

const styles = StyleSheet.create({})