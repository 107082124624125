import { StyleSheet } from 'react-native'
import { Text, View} from 'react-native-ui-lib'
import React, {useEffect, useState} from 'react'
import * as Location from 'expo-location';

export default function Aguarde() {
    const [data, setData] = useState(null);
    const getData = async () => {
        let { status } = await Location.requestForegroundPermissionsAsync();
        console.log(status)    
        setData(status);
    };
    useEffect(() => {
        getData();
    }, []);    

    return (
        <View flex>
            <Text marginT-200 marginH-20 style={styles.text}>Aguardando definição...</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    text:{
        fontSize: 24,
    }
})