import { StyleSheet, FlatList, Dimensions, Button, ScrollView } from 'react-native';
import { View, TextField, Text, Image, Card } from 'react-native-ui-lib';
import HTMLView from 'react-native-htmlview';
import React, { useState, useEffect } from 'react';

export default function Noticia({ route, navigation }) {
  const { idNoticia } = route.params;
  //var id = idNoticia.idNoticia;
  var id = idNoticia.id;
  const [data, setData] = useState(null);
  console.log('IdNoticia: ',id)
  const getData = async () => {
    //const resp = await fetch(`https://strapi.zz4.com.br/api/noticias/${id}?populate=*`);
    //const respJson = await resp.json();
    ///////////////////
    try {
      const response = await fetch(`https://painelfila.autentique.me/api/noticia/${id}`, { headers: { 'Accept': 'application/json' } });
  
      if (!response.ok) {
        console.log('Response error:',response);
        throw new Error('Request failed.');
      }
  
      var responseData = await response.json();
      console.log('JSON data:', responseData);
      //return responseData;
    } catch (error) {
      console.error(error);
      return;
    }    
    ///////////////////    
    let aData = Array();
    //aData.push(respJson.data);
    aData.push(responseData);
    const data = aData;
    console.log(JSON.stringify(data));
    setData(data);
  };
  useEffect(() => {
    getData();
  }, []);  
  const { width } = Dimensions.get('window');
  //const ratio = 2 / 3;
  const ratio = 9 / 16;
  //const baseUrl = "https://strapi.zz4.com.br";
  const baseUrl = "https://painelfila.autentique.me/storage/";
  const embedStyles = StyleSheet.create({
    p: {
      fontSize: 18,
      fontFamily: 'Poppins-Regular'
    },
    a: {
      fontWeight: '300',
      color: '#FF3366',
    }
  });
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    imgContainer: {
      flexDirection: 'row'
    },
    image: {
      flex: 1,
      width: "100%",
      height: width * ratio,
      aspectRatio: 1 / ratio,
    },
    button:{
      borderColor: "green",
      color: "green",
      padding: 5,
      borderRadius: 50
    },
    item: {
      backgroundColor: "blue",
      color: "white",
      padding: 2,
      margin: 2,
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold'
    }
  });  
  const Item = ({ idNoticia, image, titulo, conteudo, index }) => (
    <>
      <Image  style={styles.image}
              source={{ uri: baseUrl + image }} //using uri property
              resizeMode="cover"
        />
    <View>
      <View paddingH-20 marginB-20 style={styles.container}>
        <View br50 paddingB-20 style={styles.imgContainer}>
        </View>
        <View paddingB-20>
          <Text style={{ fontFamily: 'Poppins-Bold', fontSize: 22 }} text65BO center>{titulo}</Text>
        </View>
        <HTMLView
            value={conteudo}
            stylesheet={embedStyles}
        />
      </View>
    </View>
    </>
  );
  const renderItem = ({ item, index }) => (
    <Item style={styles.container} idNoticia={item.id} image={item.image ? item.image : '/uploads/Ativo_1_5x_f9ab1c3403.png'} titulo={item.title} conteudo={item.content} index={index} />
  );

  return (
    <View flex marginT-0 bg-white>
      {data && (
        <FlatList
          data={data}
          renderItem={renderItem}
        />
      )}
    </View>
  )
}