import { StyleSheet,View, Text, Image, Button } from 'react-native'
import React, { useRef } from "react";
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';

export default function LocalizaZoomCtrl() {
  //const zoomableViewRef = createRef(ReactNativeZoomableView);
  const zoomableViewRef = useRef(null);

  return (
    <View style={styles.container}>
      <View style={styles.zoomWrapper}>
        <ReactNativeZoomableView
          ref={zoomableViewRef}
          bindToBorders={true}
        >
          <Text style={styles.caption}>HelloWorld</Text>
        </ReactNativeZoomableView>
      </View>

      <View style={styles.controlWrapperLeft}>
        {/* Here you see some examples of moveBy */}
        <Button onPress={() => zoomableViewRef.current.moveBy(-30, 0)} title="⬅️" />
        <Button onPress={() => zoomableViewRef.current.moveBy(30, 0)} title="➡️" />
        <Button onPress={() => zoomableViewRef.current.moveBy(0, -30)} title="⬆️" />
        <Button onPress={() => zoomableViewRef.current.moveBy(0, 30)} title="⬇️" />

        {/* Here you see an example of moveTo */}
        <Button onPress={() => zoomableViewRef.current.moveTo(300, 200)} title="Move to" />
      </View>

      <View style={styles.controlWrapperRight}>
        {/* Here you see examples of zoomBy */}
        <Button onPress={() => zoomableViewRef.current.zoomBy(-0.1)} title="-" />
        <Button onPress={() => zoomableViewRef.current.zoomBy(0.1)} title="+" />

        {/* Here you see an example of zoomTo */}
        <Button onPress={() => zoomableViewRef.current.zoomTo(1)} title="reset" />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
    },
    box: {
      width: 60,
      height: 60,
      marginVertical: 20,
    },
    zoomWrapper:{
        flex: 1
    },
    caption:{
        flex: 1
    },
    controlWrapperLeft:{
        flex: 1
    },
    controlWrapperRight:{
        flex: 1
    }

  });