import { View, Text, Image } from 'react-native'
import React from 'react'

export default function LogoTitle() {
  return (
    <View>
        <Image
        style={{ width: 100, 
                    height: 40 
                }}
        source={require('../../assets/logo_aventura.png')}
        />
    </View>
  )
}