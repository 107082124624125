import * as React from 'react';
import { WebView } from 'react-native-webview';
import { StyleSheet } from 'react-native';
import Constants from 'expo-constants';

export default function TesteWebview() {
  return (
    <WebView
      style={styles.container}
      source={{ uri: 'https://testes.zz4.com.br/?v=2' }}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //marginTop: Constants.statusBarHeight,
    backgroundColor: '#F6E1B6'
  },
});
