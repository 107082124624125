import React, {useState} from 'react';
import {Alert, Modal, StyleSheet, Pressable, ImageBackground, Image, Dimensions, ScrollView} from 'react-native';
import { View, Text } from 'react-native-ui-lib';

export default function MenuModal({ navigation }) {
    return (
      <ScrollView>
      <View flex-1 style={{ flex:1, 
                            flexDirection:"column", 
                            height: Dimensions.get('screen').height,
                            justifyContent: 'space-around',
                          }}>
        <ImageBackground
          source={require('../../assets/background_verde.png')}
          resizeMode='cover'
          style={{flex:1}}
        >
          <Text style={styles.textStyleTitle}>Menu</Text>
          <Pressable
            style={[styles.button]}
            onPress={() => navigation.goBack()}>
            <Image style={styles.imageStyle} source={require('../../assets/ICN-INGRESSOS.png')} />
            <Text style={styles.textStyle}>Ingressos</Text>
          </Pressable>
          <Pressable
            style={[styles.button]}
            onPress={() => navigation.goBack()}>
            <Image style={styles.imageStyle} source={require('../../assets/icon-aj-sobre.png')} />
            <Text style={styles.textStyle}>Sobre o Parque</Text>
          </Pressable>
          <Pressable
            style={[styles.button]}
            onPress={() => navigation.goBack()}>
            <Image style={styles.imageStyle} source={require('../../assets/ICN-PROMOCOES.png')} />
            <Text style={styles.textStyle}>Promoções</Text>
          </Pressable>
          <Pressable
            style={[styles.button]}
            onPress={() => navigation.goBack()}>
            <Image style={styles.imageStyle} source={require('../../assets/ICN-RESTAURANTES.png')} />
            <Text style={styles.textStyle}>Restaurantes</Text>
          </Pressable>
          <Pressable
            style={[styles.button]}
            onPress={() => navigation.goBack()}>
            <Image style={styles.imageStyle} source={require('../../assets/ICN-LOJA.png')} />
            <Text style={styles.textStyle}>Loja de Souvenirs</Text>
          </Pressable>
          <Pressable
            style={[styles.button]}
            onPress={() => navigation.goBack()}>
            <Image style={styles.imageStyle} source={require('../../assets/ICN-ESTACIONAMENTO.png')} />
            <Text style={styles.textStyle}>Estacionamento</Text>
          </Pressable>
          <Pressable
            style={[styles.button]}
            onPress={() => navigation.goBack()}>
            <Image style={styles.imageStyle} source={require('../../assets/ICN-WEBSITE.png')} />
            <Text style={styles.textStyle}>Website e Mídias</Text>
          </Pressable>
          <Pressable
            style={[styles.button, styles.buttonClose]}
            onPress={() => navigation.goBack()}>
            <Text style={styles.textStyleClose}>Fechar</Text>
          </Pressable>
        </ImageBackground>
      </View>
      </ScrollView>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
      },
      modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
      },
      smallbutton: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
      },
      button: {
        alignItems: 'left',
        justifyContent: 'left',
        paddingVertical: 12,
        marginBottom: 12,
        paddingHorizontal: 5,
        marginHorizontal: 20,
        borderRadius: 15,
        elevation: 3,
        backgroundColor: '#fff',
        flexDirection: 'row'
      },      
      buttonClose: {
        backgroundColor: 'green',
        marginBottom: 0,
        justifyContent: 'center'
      },
      textStyle: {
        color: "#000",
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20,
        paddingTop: 5

      },
      textStyleClose: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
      },      
      textStyleTitle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10
      },
      modalText: {
        marginBottom: 15,
        textAlign: 'center',
      },    
      imageStyle: {
        marginLeft: 10,
        height: 24,
        width: 24
        //paddingHorizontal: 20
      }
})