import { StyleSheet, FlatList, Dimensions, Button, ScrollView } from 'react-native';
import { View, TextField, Text, Image, Card } from 'react-native-ui-lib';
import HTMLView from 'react-native-htmlview';
import React, { useState, useEffect } from 'react';
import ImageMapper from '../Components/ImageMapper';

export default function Localiza() {
  const [imageSource, setImageSource] = useState('');

  useEffect(() => {
    //const loremPicsumUrl = 'https://picsum.photos/250/500';
    //setImageSource(loremPicsumUrl);
    //const theImageSource = require('../../assets/logo_aventura.png');
    // Sem bordas...
    const theImageSource = 'https://testes.zz4.com.br/assets/img/mapinhaW300.jpg';
    setImageSource(theImageSource);
  }, []);

  const onAnyAreaPress = (item, idx, event) => {
    // Manejar la lógica cuando se hace clic en cualquier área
    console.log('Área clickeada:', item);
  };

  return (
    <ImageMapper
      containerStyle={{ 
        flex:1, 
        flexDirection:"column", 
        height: Dimensions.get('screen').height,
        justifyContent: 'space-around',
        backgroundColor: '#F6E1B6',
        borderWidth: '0',
        borderColor:'#F6E1B6',
        shadowColor: '#F6E1B6',
        elevation: 0,
      }}
      imgHeight={434}
      imgWidth={305}
      imgSource={{ uri: imageSource ? imageSource: null }}
      imgMap={[
        {
          id: 'area1',
          shape: 'rectangle',
          x1: 50,
          y1: 50,
          x2: 150,
          y2: 150,
          fill: 'rgba(255, 0, 0, 0.5)', // Rojo
        },
        {
          id: 'area2',
          shape: 'circle',
          x1: 200,
          y1: 100,
          radius: 30,
          fill: 'rgba(255, 0, 0, 0.5)', // Rojo
        },
      ]}
      onPress={(item, idx, event) => onAnyAreaPress(item, idx, event)}
      selectedAreaId="my_area_id"
    />
  );  
}

const embedStyles = StyleSheet.create({
  body: {
    flex: 1,
  },
  img: {
    width:"911", 
    height:"1294"
  }
});
