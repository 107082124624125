import { ImageBackground, Dimensions, StyleSheet, Pressable } from 'react-native'
import {View, TextField, Text, Button} from 'react-native-ui-lib';
import React from 'react'
import { useNavigation } from '@react-navigation/native';

export default function Tbd() {
  const navigation = useNavigation();
  return (    
    <View flex>
      <ImageBackground 
        source = {require('../../assets/tela_entrada_login_2.png')}
        resizeMode='cover'
        style={{flex:1,
                flexDirection: 'column',
                height: Dimensions.get('screen').height + 200,
                width: Dimensions.get('screen').width,
                position: 'absolute',
                top: 20
        }}
      />
      <View flex marginT-350>
        <Text style={styles.textStyle}>Viaje no tempo e descubra</Text>
        <Text style={styles.textStyle}>o Mundo dos Dinossauros</Text>
        <Pressable style={styles.button}  
              onPress={() => {
                  navigation.navigate('HomeTabs', {
                  teste: 1
              });
            }}
>
          <Text style={styles.textLogin}>LOGIN</Text>
        </Pressable>        
        <Text marginT-20 style={styles.textStyle}>CRIE SUA CONTA</Text>
      </View>
    </View>    
  )
}

const styles = StyleSheet.create({
  textStyle: {
    //flex: 1,
    color: "#fff",
    fontWeight: 'bold',
    textAlign: 'center',
    paddingHorizontal: 20,
    //paddingTop: 150,
    fontSize: 20,
    //justifyContent: "space-between"
  },
  button:{
    backgroundColor: "yellow",
    color: "#000",
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 25,
    elevation: 3,
    marginHorizontal: 20,
    marginTop: 20
  },
  textLogin: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: '#000',
  }
})