import { StyleSheet, FlatList, Dimensions, Button } from 'react-native';
import { View, TextField, Text, Image, Card } from 'react-native-ui-lib';
import React, { useState, useEffect } from 'react';
import HTMLView from 'react-native-htmlview';
import Divider from './Divider';
import CustomButton from './CustomButton';
import { useNavigation } from '@react-navigation/native';
// E agora? Com lint?
export default function NewsList() {
  const [data, setData] = useState(null);
  const getData = async () => {
    ///////////////////
    try {
      const response = await fetch("https://painelfila.autentique.me/api/noticias", { headers: { 'Accept': 'application/json' } });
  
      if (!response.ok) {
        throw new Error('Request failed.');
      }
  
      var responseData = await response.json();
      console.log('JSON data:', responseData);
      //return responseData;
    } catch (error) {
      console.error(error);
      return;
    }    
    ///////////////////
    setData(responseData);
  };
  useEffect(() => {
    getData();
  }, []);
  const navigation = useNavigation();
  const { width } = Dimensions.get('window');
  const ratioTop = 2 / 3;
  const ratio = 9 / 16;
  //const baseUrl = "https://strapi.zz4.com.br";
  const baseUrl = "https://painelfila.autentique.me/storage/";
  const embedStyles = StyleSheet.create({
    p: {
      fontSize: 18,
      fontFamily: 'Poppins-Regular'
    },
    a: {
      fontWeight: '300',
      color: '#FF3366',
    }
  });
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    imgContainer: {
      flexDirection: 'row'
    },
    image: {
      flex: 1,
      width: "100%",
      height: width * ratio,
      aspectRatio: 1 / ratio,
      borderRadius: 20
    },
    imageTop: {
      flex: 1,
      width: "100%",
      height: width * ratioTop,
      aspectRatio: 1 / ratioTop,
      marginBottom: 40,
    },    
    button:{
      borderColor: "green",
      color: "green",
      padding: 5,
      borderRadius: 50
    },
    item: {
      backgroundColor: "blue",
      color: "white",
      padding: 2,
      margin: 2,
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold'
    }
  });

  //const Item = ({ idNoticia, image, titulo, chamada, index }) => (
    const Item = ({ id, image, title, content, index }) => (
    <View>
      {index===0 && <Image style={styles.imageTop} source={{ uri: baseUrl + image }} resizeMode="cover"/>}
      <View paddingH-20 marginB-20 style={styles.container}>
        <View br50 paddingB-20 style={styles.imgContainer}>
        {index>0 && <Image style={styles.image} source={{ uri: baseUrl + image }} resizeMode="cover"/>}
        </View>
        <View paddingB-20>
          <Text style={{ fontFamily: 'Poppins-Bold', fontSize: 22 }} text65BO center>{title}</Text>
        </View>
        <HTMLView
            value={content}
            stylesheet={embedStyles}
        />
      </View>
      <View left paddingT-5 paddingL-15>
          <CustomButton 
            title={"Saiba mais"}
            onPress={() => {
              /* 1. Navigate to the Details route with params */
              navigation.navigate('Noticia', {
                idNoticia: {id}
              });
            }}
          ></CustomButton>
      </View>
      <Divider></Divider>
    </View>
  );
  const renderItem = ({ item, index }) => (
    <Item style={styles.container} id={item.id} image={item.image ? item.image : '/uploads/Ativo_1_5x_f9ab1c3403.png'} title={item.title} content={item.content} index={index} />
  );

  return (
    <View flex bg-white>
      {data && (
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      )}
    </View>
  )
}
