import * as React from 'react';
import { View, Text, Button } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import MenuModal from '../Components/MenuModal';
import LogoTitle from '../Components/LogoTitle'

const ModalMenuStack = createStackNavigator();

export default function ModalMenuStackNavigation() {
    return (
          <ModalMenuStack.Navigator>
            <ModalMenuStack.Group screenOptions={{ 
                presentation: 'modal', 
                headerTitle: (props) => <LogoTitle {...props} /> ,
                headerTitleStyle: { textAlign: 'center', alignSelf: 'center' },
                headerBackTitleVisible: false,
                headerTitleAlign: 'center',
                headerTintColor: '#fff',
                headerShadowVisible: false,
                headerStyle: {
                    backgroundColor: '#000',
                },
                headerLeft: (props) => {
                    return (
                      <>
                      </>
                    );
                  },                
                }}>
              <ModalMenuStack.Screen name="MyModal" component={MenuModal} />
            </ModalMenuStack.Group>
          </ModalMenuStack.Navigator>
      );
}