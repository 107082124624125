import { Text } from 'react-native'
import { View, Image } from 'react-native-ui-lib';
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from '../Screens/Home';
import Noticia from '../Screens/Noticia';
import LogoTitle from '../Components/LogoTitle'

const HomeStack = createNativeStackNavigator();

export default function HomeStackNavigation() {
  return (
    <HomeStack.Navigator 
        screenOptions={{ 
          headerTitle: (props) => <LogoTitle {...props} /> ,
          headerTitleStyle: { textAlign: 'center', alignSelf: 'center' },
          //headerRight: () => (<View flex><Text>Teste</Text></View>),
          headerBackTitleVisible: false,
          headerTitleAlign: 'center',
          headerTintColor: '#fff',
          headerStyle: {
              backgroundColor: '#000',
              //flex:1
              //padding: 5,
              //height: 100
          },
          headerLeft: (props) => {
              return (
                <>
                </>
              );
            },
        }}
    >
      <HomeStack.Screen 
            name="HomeInStack" 
            component={Home} 
            />
      <HomeStack.Screen 
        name="Noticia" 
        component={Noticia} 
        options={
            {
              headerBackVisible: true
            }
        }
        />
    </HomeStack.Navigator>
  )
}